<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <ChartDoughnutCard
          :is-loading="isLoadingAddressVerificationsStats"
          title="Address Verification by Entity"
          :items="verificationsByEntityStats"
          :description="`${verificationsByEntityStatsCount} Addresses`"
          :to="{ name: 'clean-verify-address-verifications-list' }"
        />
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <ChartDoughnutCard
          :is-loading="isLoadingAddressVerificationsStats"
          title="Verification Actions"
          :items="verificationActionsStats"
          :description="`${verificationActionsStatsCount} Actions`"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ChartDoughnutCard from '@/components/common/ChartDoughnutCard'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    ChartDoughnutCard,
  },
  data() {
    return {
      isLoadingAddressVerificationsStats: false,
    }
  },
  computed: {
    ...mapState('clean-verify', [
      'verificationsByEntityStats',
      'verificationActionsStats',
    ]),
    ...mapGetters('clean-verify', [
      'verificationsByEntityStatsCount',
      'verificationActionsStatsCount',
    ]),
  },
  mounted() {
    this.fetchAddressVerificationsStats()
  },
  methods: {
    ...mapActions('clean-verify', ['getAddressVerificationsStats']),
    async fetchAddressVerificationsStats() {
      this.isLoadingAddressVerificationsStats = true
      await this.getAddressVerificationsStats()
      this.isLoadingAddressVerificationsStats = false
    },
  },
}
</script>
