<template>
  <div style="max-height: 100%">
    <canvas ref="chart" />
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  DoughnutController,
  Legend,
  Tooltip,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(DoughnutController, ArcElement, Legend, Tooltip)

export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    new Chart(this.$refs.chart, {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: this.chartData,
      options: {
        plugins: {
          legend: {
            position: 'bottom',
          },
          datalabels: {
            color: 'white',
            font: {
              weight: 'bold',
              size: 14,
            },
          },
        },
      },
    })
  },
}
</script>
