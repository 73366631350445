<template>
  <ChartCard
    :is-loading="isLoading"
    :title="title"
    :title-tooltip="titleTooltip"
    :description="description"
    :to="to"
    :to-label="toLabel"
  >
    <ChartDoughnut v-if="chartData" :chart-data="chartData" class="mx-auto" />
    <div v-else class="d-flex justify-center align-center" style="flex-grow: 1">
      <span v-if="isLoading">Loading...</span>
      <span v-else>No data available</span>
    </div>
  </ChartCard>
</template>

<script>
import ChartDoughnut from './ChartDoughnut'
import ChartCard from './ChartCard'

export default {
  components: {
    ChartCard,
    ChartDoughnut,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    titleTooltip: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
    toLabel: {
      type: String,
      default: 'See all',
    },
    to: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    chartData() {
      if (this.items.length > 0) {
        const labels = []
        const data = []
        const backgroundColor = []

        this.items.forEach((stat) => {
          labels.push(stat.label)
          data.push(stat.count)
          backgroundColor.push(stat.color)
        })

        return {
          labels,
          datasets: [
            {
              data,
              borderWidth: 0,
              backgroundColor,
            },
          ],
        }
      }
      return null
    },
    hasContent() {
      return (
        this.chartData &&
        this.chartData.labels &&
        this.chartData.labels.length > 0
      )
    },
  },
}
</script>
